<template>
    <div>
        <p class="h6 accent fst-italic text-center">There are no events yet!</p>
      <img
        src="../assets/img/empty.jpg"
        alt=""
        class="img-fluid re d-block m-auto rounded-3 shadow mb-5"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: "Empty-vue",
  };
  </script>
  
  <style scoped>
    .re{
        width: 50%;
    }
  </style>
  