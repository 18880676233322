<template>
  <div>
    <img
      src="../assets/img/spinner.gif"
      alt=""
      class="img-fluid spin d-block m-auto"
    />
    <!-- <img
      src="../assets/img/loader.gif"
      alt=""
      class="img-fluid spin d-block m-auto"
    /> -->
  </div>
</template>

<script>
export default {
  name: "Spinner-vue",
};
</script>

<style></style>
